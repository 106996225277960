const JustLoadingLineModul = [
    'krisna.gunara@trees4trees.org', // NURSERY COORDINATOR ARJASARI
    'odik.subarna@trees4trees.org', // NURSERY COORDINATOR ARJASARI
    'yandi.indriansyah@trees4trees.org', // NURSERY COORDINATOR ARJASARI
    'arif.sambas@trees4trees.org', // NURSERY COORDINATOR CIMINYAK
    'muhamad.fadjar@trees4trees.org', // NURSERY COORDINATOR CIMINYAK
    'saepudin@trees4trees.org', // NURSERY COORDINATOR CIMINYAK
    'nada.radilla@trees4trees.org' // NURSERY COORDINATOR CIMINYAK
]

export default JustLoadingLineModul