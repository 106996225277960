export default [
    {
        id: 'project',
        icon: 'mdi-calendar',
        label: 'Project',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'monitoring2_no',
        icon: 'mdi-ticket',
        label: 'Nomor Monitoring 2',
        type: 'text',
        model: '',
        filter: false,
        list: true
    },
    {
        col: 12,
        id: 'program_year',
        icon: 'mdi-ticket',
        label: 'Tahun Program',
        type: 'select-program-year',
        model: '',
        filter: true,
        list: true
    },
    {
        id: 'mu_name',
        icon: 'mdi-calendar',
        label: 'MU',
        type: 'special-select',
        model: '',
        filter: true,
        list: true,
        specialCase: true,
        items: []
    },
    {
        id: 'ta_name',
        icon: 'mdi-calendar',
        label: 'TA',
        type: 'select',
        model: '',
        filter: true,
        list: true,
        items: []
    },
    {
        id: 'village_name',
        icon: 'mdi-calendar',
        label: 'Desa',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'fc_name',
        icon: 'mdi-calendar',
        label: 'FC',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'ff_name',
        icon: 'mdi-calendar',
        label: 'FF',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'farmer_name',
        icon: 'mdi-calendar',
        label: 'Petani',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'lahan_type',
        icon: 'mdi-calendar',
        label: 'Tipe Lahan (T / R)',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'ktp_no',
        icon: 'mdi-calendar',
        label: 'KTP',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'farmer_address',
        icon: 'mdi-calendar',
        label: 'Alamat',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'lahan_no',
        icon: 'mdi-calendar',
        label: 'Kode Lahan',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'last_kayu_amount',
        icon: 'mdi-calendar',
        label: 'Jumlah Kayu Awal',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'total_kayu',
        icon: 'mdi-calendar',
        label: 'Jumlah Kayu Monitoring',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'survival_rate_kayu',
        icon: 'mdi-calendar',
        label: 'Survival Rate Kayu (%)',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'last_mpts_amount',
        icon: 'mdi-calendar',
        label: 'Jumlah MPTS Awal',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'total_mpts',
        icon: 'mdi-calendar',
        label: 'Jumlah MPTS Monitoring',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'survival_rate_mpts',
        icon: 'mdi-calendar',
        label: 'Survival Rate MPTS (%)',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'last_kayu_mpts_amount',
        icon: 'mdi-calendar',
        label: 'Jumlah Kayu + MPTS Awal',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'total_kayu_mpts',
        icon: 'mdi-calendar',
        label: 'Jumlah Pohon Monitoring',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'survival_rate_kayu_mpts',
        icon: 'mdi-calendar',
        label: 'Survival Rate Kayu + MPTS (%)',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
    {
        id: 'document_no',
        icon: 'mdi-calendar',
        label: 'Document No',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'land_area',
        icon: 'mdi-calendar',
        label: 'Area Lahan',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'planting_area',
        icon: 'mdi-calendar',
        label: 'Area Tanam',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'planting_pattern',
        icon: 'mdi-calendar',
        label: 'Pola Tanam',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'land_distance',
        icon: 'mdi-calendar',
        label: 'Jarak Lahan',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'access_lahan',
        icon: 'mdi-calendar',
        label: 'Akses Lahan',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'coordinate',
        icon: 'mdi-calendar',
        label: 'Koordinat Lahan',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'land_status',
        icon: 'mdi-calendar',
        label: 'Status Lahan',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'lahan_condition',
        icon: 'mdi-calendar',
        label: 'Kondisi Lahan',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'planting_date',
        icon: 'mdi-calendar',
        label: 'Tanggal Tanam',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    {
        id: 'total_detail',
        icon: 'mdi-calendar',
        label: 'Total Detail Pohon',
        type: 'text',
        model: '',
        filter: false,
        list: false,
    },
    // {
    //     id: 'qty_std',
    //     icon: 'mdi-calendar',
    //     label: 'Kuantitas Standar',
    //     type: 'text',
    //     model: '',
    //     filter: false,
    //     list: true,
    // },
    {
        id: 'is_validate',
        icon: 'mdi-calendar',
        label: 'Status Verifikasi',
        type: 'text',
        model: '',
        filter: false,
        list: true,
    },
]