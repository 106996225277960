const Cirasea = [
    'Denny.Muliawan@trees4trees.org',
    // 
    'odik.subarna@trees4trees.org',
    'faza.meidina@trees4trees.org',
    'abdul.rohman@trees4trees.org',
    // 
    'syahrul.ramadhan@trees4trees.org',
    // Faza Meidina
]
const Ciminyak = [
    'wawan.sugeng@trees4trees.org',
    'saepudin@trees4trees.org',
    // 'muhamad.fadjar@trees4trees.org',
    'arif.sambas@trees4trees.org',
    'windi.widia@trees4trees.org',
    'nada.radilla@trees4trees.org'
]

const Soreang = [
    'muhamad.fadjar@trees4trees.org',
    'krisna.gunara@trees4trees.org',
    'nurwenda.aqlima@trees4trees.org',
    'yandi.indriansyah@trees4trees.org',
]

const Kebumen = [
    'muslim@trees4trees.org',
    'rika@trees4trees.org',
]

const Pati = [
    'um_pati@t4t.org',
    'falah.nurwirana@trees4trees.org',
    'rika@trees4trees.org'
]

export {
    Cirasea,
    Ciminyak,
    Soreang,
    Kebumen,
    Pati
}